import React, { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import useOnScreen from 'shared/hooks/use-on-screen'
import useUser from 'shared/hooks/use-user'
import LoadingIcon from 'shared/icons/loading-icon'
import { baseServerSideProps } from 'shared/utils/base-server-side-props'
import * as postsApi from 'modules/community/api/posts-api'
import AddPost from 'modules/community/components/manage-post/create-post'
import { PostPinnedList } from 'modules/community/components/pinned-post/post-pinned-list'
import { PostAnnounceSkeleton } from 'modules/community/components/post-announce/components/post-announce-skeleton'
import PostAnnounce from 'modules/community/components/post-announce/post-announce'
import { ScrollToTopButton } from 'modules/community/components/scroll-to-top-button'
import { PostCommentsInfoProvider } from 'modules/community/context/post-comments-info'
import useCommentsInfo from 'modules/community/hooks/use-comments-info'
import { useCommunityWithTopics } from 'modules/community/hooks/use-community-with-topics'
import { useDeletePost } from 'modules/community/hooks/use-delete-post'
import { useDeletePostAndBanUser } from 'modules/community/hooks/use-delete-post-and-ban'
import useIsUserMember from 'modules/community/hooks/use-is-user-member'
import { useToggleLike } from 'modules/community/hooks/use-toggle-like'
import { PostInterface } from 'modules/community/types/post-interface'
import MobileNav from 'modules/header/components/mobile-nav'

function Community() {
  const { t } = useTranslation()
  const router = useRouter()
  const { communityPath } = router.query as { communityPath: string }
  const { user } = useUser()
  const { isUserMember } = useIsUserMember()

  const [lastPostId, setLastPostId] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [isEnd, setIsEnd] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)

  const { mutate, data: posts = [], isLoading: isLoadingPosts } = useSWR<PostInterface[]>('posts')
  const { data: community } = useCommunityWithTopics()
  const commentsInfoMap = useCommentsInfo({ postIds: posts.map(post => post.id) })
  const deletePost = useDeletePost({ mutatePosts: mutate })
  const handleLikeClick = useToggleLike({
    mutatePosts: mutate,
    communityPath: communityPath,
  })
  const deletePostAndBanUser = useDeletePostAndBanUser({ mutatePosts: mutate, communityPath })

  const loadPosts = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await postsApi.getPostsByLastId(communityPath as string, lastPostId)
      const lastPost = data.items.at(-1)
      if (lastPost) {
        setLastPostId(lastPost.id)
      }
      if (!data.hasMore) {
        setIsEnd(true)
      }
      await mutate((prevPosts = []) => [...prevPosts, ...data.items])
      setLoading(false)
    } catch (e) {
      setIsEnd(true)
      toast.error(t('core.error.internal_error_message'))
      setLoading(false)
    }
  }, [communityPath, lastPostId, mutate, t])

  useEffect(() => {
    setLastPostId(0)
    setIsEnd(false)
    mutate(() => [])
  }, [])

  useEffect(() => {
    if (isVisible && !isLoading && community && !isEnd && user) {
      loadPosts()
    }
  }, [isVisible, community, isEnd, user])

  return (
    <>
      <MobileNav />
      <div>
        {isUserMember && <AddPost />}
        {isLoading && posts.length === 0 && (
          <>
            <PostAnnounceSkeleton key={1} />
            <PostAnnounceSkeleton key={2} />
          </>
        )}
        <PostPinnedList />
        <PostCommentsInfoProvider commentsInfoMap={commentsInfoMap}>
          {posts.map(post => (
            <PostAnnounce
              key={post.id}
              deletePost={deletePost(post.id, posts)}
              deletePostAndBanUser={deletePostAndBanUser(post.id, posts, post.userId)}
              post={post}
              handleLikeClick={handleLikeClick(post)}
            />
          ))}
        </PostCommentsInfoProvider>
        {isLoading && posts.length > 0 && (
          <div className="flex justify-center">
            <LoadingIcon />
          </div>
        )}
        <div ref={ref} />
      </div>
      <ScrollToTopButton />
    </>
  )
}

export const getServerSideProps = baseServerSideProps

export default Community
